import React, {useState} from 'react';
import './Dashboard.css';
import {useNavigate} from 'react-router-dom'; // Import useHistory hook from React Router
import {useRef} from 'react';
import {useEffect} from 'react';
import {FaUsers, FaChartLine, FaUserPlus, FaCalendarWeek, FaCalendarDay, FaEye, FaEyeSlash} from 'react-icons/fa';


function Dashboard() {
    const [activeSection, setActiveSection] = useState('home');
    const fileInputRef = useRef(null);
    const formData = new FormData();
    const [userStats, setUserStats] = useState({
        total_users: 0,
        new_users_last_month: 0,
        monthly_growth: '',
        new_users_last_week: 0,
        weekly_growth: '',
        new_users_last_day: 0
    });
    const [users, setUsers] = useState([]);

    // Password Update
    const [passwordData, setPasswordData] = useState({
        name: '', email: '', password: '', confirmPassword: ''
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [warning, setWarning] = useState('');

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setPasswordData({
            ...passwordData, [name]: value
        });
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmPasswordShown => !confirmPasswordShown);
    };

    const updatePassword = async (event) => {
        event.preventDefault();
        if (passwordData.password !== passwordData.confirmPassword) {
            setWarning('Passwords do not match!');
            return;
        }
        setWarning('');

        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from local storage
        try {
            const response = await fetch('https://api.aidealsale.com/user/update', {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`,
                }, body: JSON.stringify({
                    name: passwordData.name, email: passwordData.email, password: passwordData.password
                })
            });

            const data = await response.json();
            if (response.ok) {
                alert(data.message); // Show success message
            } else {
                console.error('Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

        const fetchData = async () => {
            try {
                const response = await fetch('https://api.aidealsale.com/admin/user-stats/', {
                    method: 'GET', // Make sure it's a GET request
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserStats(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchUsers = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch('https://api.aidealsale.com/admin/get-users/', {
                    method: 'GET', headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data.map(user => ({
                        ...user,
                        isApproved: user.approval_status === "Approved" || user.approval_status === null,
                        approval_status: user.approval_status === null ? "Added by Admin" : user.approval_status
                    })));
                } else {
                    const errorData = await response.json();
                    console.error('Failed to fetch users:', errorData.detail || 'Unknown error');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchData();
        fetchUsers();

    }, []);


    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/');
    };


    if (fileInputRef.current && fileInputRef.current.files) {
        Array.from(fileInputRef.current.files).forEach(file => {
            formData.append('files', file);
        });
    }
    const [qaData, setQaData] = useState([]);

    // Fetch QA data on component load
    useEffect(() => {
        const fetchQAData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch('https://api.aidealsale.com/admin/qa', {
                    method: 'GET', headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setQaData(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchQAData();
    }, []);

    // Function to handle PDF download
    const handleDownloadPdf = async (userId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://api.aidealsale.com/admin/qa/${userId}`, {
                method: 'GET', headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `QA_${userId}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to download PDF');
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };


    let navigate = useNavigate(); // Initialize the useNavigate hook

    const renderContent = () => {
        switch (activeSection) {
            case 'userView':
                return (<div>
                    <h1 className="title">AIDealSale Users</h1>
                    <div className="table-container">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Authentication Method</th>
                                <th>Created At</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((user, index) => (<tr key={index}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.auth_method}</td>
                                <td>{new Date(user.created_at).toLocaleString()}</td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>);


            case 'settings':

                return (<div className="setting-cards-container">
                        <div className="setting-card">
                            <h2 className="title">Update Admin/Users Passwords</h2>
                            <form onSubmit={updatePassword} className="create-user-form">
                                <input type="text" name="name" placeholder="Name" value={passwordData.name}
                                       onChange={handleInputChange}/>
                                <input type="email" name="email" placeholder="Email" value={passwordData.email}
                                       onChange={handleInputChange}/>
                                <div className="password-field">
                                    <input type={passwordShown ? 'text' : 'password'} name="password"
                                           placeholder="Password" value={passwordData.password}
                                           onChange={handleInputChange}/>
                                    <i onClick={togglePasswordVisiblity}>{passwordShown ? <FaEye/> : <FaEyeSlash/>}</i>
                                </div>
                                <div className="password-field">
                                    <input type={confirmPasswordShown ? 'text' : 'password'} name="confirmPassword"
                                           placeholder="Confirm Password" value={passwordData.confirmPassword}
                                           onChange={handleInputChange}/>
                                    <i onClick={toggleConfirmPasswordVisiblity}>{confirmPasswordShown ? <FaEye/> :
                                        <FaEyeSlash/>}</i>
                                </div>
                                {warning && <p className="warning">{warning}</p>}
                                <button type="submit" className="update-button">Update</button>
                            </form>
                        </div>
                    </div>

                );

            case 'pdf':
                return (
                    <div className="qa-table-container">
                        <h1>Download Pdfs</h1>
                        <table>
                            <thead>
                            <tr>
                                <th>User ID</th>
                                <th>Details</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {qaData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.user_id}</td>
                                    <td>
                                        {Object.entries(item.qa_pairs).map(([question, answer], idx) => (
                                            <p key={idx}><strong>{question}:</strong> {answer}</p>
                                        ))}
                                    </td>
                                    <td>
                                        <button onClick={() => handleDownloadPdf(item.user_id)}>Download PDF</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                );

            default:
                return (<>
                    <div>
                        <h1>AIDealSale Content Management Dashboard</h1>

                    </div>
                    <div className="card-container"
                         style={{display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center'}}>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaUsers size={50}/>
                            <h3>Total Users</h3>
                            <p>{userStats.total_users}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaUserPlus size={50}/>
                            <h3>New Users Last Month</h3>
                            <p>{userStats.new_users_last_month}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaChartLine size={50}/>
                            <h3>Monthly Growth</h3>
                            <p>{userStats.monthly_growth}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaCalendarWeek size={50}/>
                            <h3>New Users Last Week</h3>
                            <p>{userStats.new_users_last_week}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaChartLine size={50}/>
                            <h3>Weekly Growth</h3>
                            <p>{userStats.weekly_growth}</p>
                        </div>
                        <div className='card' style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px'
                        }}>
                            <FaCalendarDay size={50}/>
                            <h3>New Users Last Day</h3>
                            <p>{userStats.new_users_last_day}</p>
                        </div>
                    </div>
                </>);
        }
    };


    return (<div className="dashboard-container">
        <aside className="sidebar">
            <h2>Menu</h2>
            <button onClick={() => setActiveSection('home')} className={activeSection === 'home' ? 'active' : ''}>
                Home
            </button>
            <button onClick={() => setActiveSection('userView')}
                    className={activeSection === 'userView' ? 'active' : ''}>
                View Users
            </button>
            <button onClick={() => setActiveSection('pdf')}
                    className={activeSection === 'pdf' ? 'active' : ''}>
                Download Pdfs
            </button>
            <button onClick={() => setActiveSection('settings')}
                    className={activeSection === 'settings' ? 'active' : ''}>
                Settings
            </button>
            <button onClick={handleLogout}>Logout</button>
        </aside>
        <main className="main-content">
            {renderContent()}
        </main>
    </div>);
}

export default Dashboard;
